import { AfterViewInit, Component, DestroyRef, inject } from "@angular/core";
import { FormControl, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ReportingService } from "../../reporting/reporting.service";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose } from "@angular/material/dialog";
import { deleteItemFromArray } from "../shared-functions";
import { SharedService } from "../shared.service";
import { WsService } from "../../ws.service";
import { GeneralSettingDTO, StudyAvailability, StudyItem } from "../../model";
import { forkJoin, Observable, of } from "rxjs";
import { FileService } from "../file-explorer";
import { dataURItoBlob } from "../../utils";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource, MatTree, MatTreeNodeDef, MatTreeNode, MatTreeNodeToggle, MatNestedTreeNode, MatTreeNodeOutlet } from "@angular/material/tree";
import { first } from "rxjs/operators";
import { sortBy } from "lodash";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { TranslateModule } from "@ngx-translate/core";
import { AsyncPipe, DatePipe } from "@angular/common";
import { MatMenuTrigger, MatMenu, MatMenuItem } from "@angular/material/menu";
import { PrintLayoutComponent } from "../print-layout/print-layout.component";
import { MatRadioGroup, MatRadioButton } from "@angular/material/radio";
import { MatInput } from "@angular/material/input";
import { MatOption } from "@angular/material/core";
import { MatSelect } from "@angular/material/select";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatProgressSpinner } from "@angular/material/progress-spinner";
import { MatTooltip } from "@angular/material/tooltip";
import { MatProgressBar } from "@angular/material/progress-bar";
import { MatIcon } from "@angular/material/icon";
import { MatIconButton, MatMiniFabButton } from "@angular/material/button";

@Component({
    selector: "ft-booklet-print",
    templateUrl: "./booklet-print.component.html",
    styleUrls: ["./booklet-print.component.scss"],
    imports: [
        MatTree,
        MatTreeNodeDef,
        MatTreeNode,
        MatTreeNodeToggle,
        MatIconButton,
        MatIcon,
        MatProgressBar,
        MatTooltip,
        MatNestedTreeNode,
        MatTreeNodeOutlet,
        MatProgressSpinner,
        MatFormField,
        MatLabel,
        MatSelect,
        FormsModule,
        ReactiveFormsModule,
        MatOption,
        MatInput,
        MatRadioGroup,
        MatRadioButton,
        MatDialogClose,
        MatMiniFabButton,
        PrintLayoutComponent,
        MatMenuTrigger,
        MatMenu,
        MatMenuItem,
        AsyncPipe,
        DatePipe,
        TranslateModule,
    ]
})
export class BookletPrintComponent implements AfterViewInit {
  data = inject(MAT_DIALOG_DATA);
  private service = inject(ReportingService);
  private shared = inject(SharedService);
  private _fileService = inject(FileService);
  private dialogRef = inject<MatDialogRef<BookletPrintComponent>>(MatDialogRef);

  layouts = [
    "1x1",
    "1x2",
    "1x3",
    "1x4",
    "2x1",
    "2x2",
    "2x3",
    "2x4",
    "3x1",
    "3x2",
    "3x3",
    "3x4",
    "3x5",
    "3x6",
    "4x2",
    "4x3",
    "4x4",
  ];

  form = new FormControl("1x1");
  selectedLayout: string = "1x1";

  studyInstanceUID: string;

  series = [];
  selectedImages = [];
  pacsPatientID: string;
  imagesArray: [][] = [];
  private chunkSize: number = 1;
  imageBackground: string = "BLACK";
  printOption: string = "BOTH";
  numberOfCopies: number = 1;
  printers: any[] = [];
  generalSetting: Observable<GeneralSettingDTO>;
  isAllLinked: boolean = true;

  treeControl = new NestedTreeControl<StudyItem>((node) => node.studyItems);
  dataSource = new MatTreeNestedDataSource<StudyItem>();
  private readonly patientID: string;

  private static chunkArray(myArray, chunkSize): any {
    const arrayLength = myArray.length;
    const tempArray = [];

    for (let index = 0; index < arrayLength; index += chunkSize) {
      tempArray.push(myArray.slice(index, index + chunkSize));
    }

    return tempArray;
  }

  hasChild = (_: number, node: StudyItem) =>
    node.studyItems && node.studyItems.length > 0;

  #destroyRef = inject(DestroyRef);

  constructor() {
    this.generalSetting = of(this.data.generalSetting);

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((value) => {
        this.selectedLayout = value;
        if (value) this.buildPrintingPages(value);
      });

    const patientID = this.data.reportingTask.patientID;
    const pacsPatientID = this.data.reportingTask.pacsPatientID;

    this.patientID = patientID;
    this.pacsPatientID = pacsPatientID;

    this.studyInstanceUID = this.data.reportingTask.studyInstanceUID;
    this.chunkSize = this.data.reportingTask.numberOfImagesPerPage;
    this.printOption = this.data.reportingTask.printOption;
    this.selectedLayout = this.data.reportingTask.layout;

    this.form.patchValue(this.selectedLayout);

    this.getPatientStudies(patientID);
  }

  public retrieveImagesIfNotAvailable(node: StudyItem) {
    node["loading"] = true;
    if (node.studyAvailability === StudyAvailability.AVAILABLE)
      this.service
        .retrieveStudy(node.studyInstanceUID)
        .pipe(takeUntilDestroyed(this.#destroyRef))
        .subscribe((res) => {
          setTimeout(() => {
            node["loading"] = false;
            if (res === 0) {
              node["error"] = false;
              this.getPatientStudies(this.patientID);
            } else node["error"] = true;
          }, 2000);
        });
  }

  private getPatientStudies(patientID: string): void {
    this.service
      .getPatientStudies(patientID)
      .pipe(first(), takeUntilDestroyed(this.#destroyRef))
      .subscribe((data) => {
        this.dataSource.data = data.map(
          (it) =>
            new StudyItem(
              it.procedureCode,
              "",
              it.pacsPatientID,
              it.studyInstanceUID,
              it.studyAvailability,
              sortBy(it.studyItems, ["name"]),
              it.studyDateTime,
              true,
              0
            )
        );
      });
  }

  cleanAndRetrieve(node: StudyItem) {
    node["loading"] = true;
    this.treeControl.collapse(node);
    this.service
      .cleanAndRetrieveStudy(node.studyInstanceUID, node.pacsPatientID)
      .pipe(first(), takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        setTimeout(() => {
          node["loading"] = false;
          if (res === 0) {
            node["error"] = false;
            this.getPatientStudies(this.patientID);
          } else node["error"] = true;
        }, 2000);
      });
  }

  isImageContainer(node: StudyItem): boolean {
    return (
      node.studyItems?.length && !!node.studyItems?.find((it) => !it.folder)
    );
  }

  selectImage(image: any) {
    const img = { name: image.name, url: image.url.replace("/thumb", "") };
    if (this.isSelected(img)) deleteItemFromArray(this.selectedImages, img);
    else this.selectedImages.push(img);

    this.imagesArray = BookletPrintComponent.chunkArray(
      this.selectedImages,
      this.chunkSize
    );
  }

  isSelected(image: any) {
    return !!this.selectedImages.find(
      (it) =>
        it.name === image.name && it.url === image.url.replace("/thumb", "")
    );
  }

  validate(printer: any) {
    this.saveImages();
    this.dialogRef.close({
      layout: this.selectedLayout,
      numberOfImagesPerPage: this.chunkSize,
      images: this.selectedImages,
      imageBackground: this.imageBackground.toLowerCase(),
      printOption: this.printOption,
      printer,
      printCount: this.numberOfCopies,
    });
  }

  selectAll(node: StudyItem) {
    if (this.isAllSelected(node)) {
      node.studyItems.forEach((img) => {
        const im = {
          name: img.name,
          url: img.url.replace("/thumb", ""),
        };
        if (this.isSelected(im)) deleteItemFromArray(this.selectedImages, img);
      });

      this.imagesArray = BookletPrintComponent.chunkArray(
        this.selectedImages,
        this.chunkSize
      );
    } else {
      node.studyItems.forEach((img) => {
        if (!this.isSelected(img)) this.selectImage(img);
      });
    }
  }

  isAllSelected(node: StudyItem): boolean {
    return node.studyItems
      .map((it) => this.isSelected(it))
      .reduce((p, c) => p && c, true);
  }

  ngAfterViewInit(): void {
    this.shared
      .getPrinters()
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((data) => {
        this.printers = data;
      });
  }

  chromePrint() {
    this.saveImages();
    this.dialogRef.close({
      layout: this.selectedLayout,
      numberOfImagesPerPage: this.chunkSize,
      images: this.selectedImages,
      imageBackground: this.imageBackground.toLowerCase(),
      printOption: this.printOption,
      printCount: this.numberOfCopies,
    });
  }

  linkOrUnlinkAll() {
    this.selectedImages.forEach((it) => (it.synchronized = !this.isAllLinked));
    this.isAllLinked = !this.isAllLinked;
  }

  uploadImageFiles(files: File[]): Observable<any> {
    // start the upload and save the progress map
    const progress = this._fileService.uploadImageFiles(files, true);

    // convert the progress map into an array
    const allProgressObservables = [];
    for (const key in progress)
      allProgressObservables.push(progress[key]["progress"]);

    // When all progress-observables are completed...
    return forkJoin(allProgressObservables);
  }

  saveImages() {
    const imagesArray: any[] = [];
    const canvas_list: any = document.querySelectorAll(
      ".cornerstone-canvas canvas"
    );
    canvas_list.forEach((canvas) =>
      imagesArray.push({
        blobData: dataURItoBlob(canvas.toDataURL()),
        filename: canvas.parentElement.id,
      })
    );

    const images = imagesArray.map(
      (img) =>
        new File([img.blobData], img.filename.replace("\\", "/"), {
          type: "image/jpg",
        })
    );

    this.uploadImageFiles(images)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((value) => {
        if (value[value.length - 1] === 100) {
          console.log("upload done!");
        }
      });
  }

  private buildPrintingPages(layout: any) {
    const sp = layout.split("x");
    this.chunkSize = parseInt(sp[0], 0) * parseInt(sp[1], 0);

    this.imagesArray = BookletPrintComponent.chunkArray(
      this.selectedImages,
      this.chunkSize
    );
  }
}
